import {
  augmentRouter,
  getRedirectGuardSchedulingFlow,
  getRoutesDev,
  getRoutesSchedulingFlow,
  getRoutesVisitsPage,
} from '@caresend/ui-components';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { RouteConfig } from 'vue-router/types/router';

import { trackPageViewSegment } from '@/functions/tracking';
import authGuard from '@/router/methods/auth';
import featureFlagGuard from '@/router/methods/featureFlags';
import loadingGuard from '@/router/methods/loading';
import partnerValidationGuard from '@/router/methods/partnerValidation';
import { routeNames } from '@/router/model';
import { routesHome } from '@/router/routes/home';
import { routesLogin } from '@/router/routes/login';
import { routesSignUp } from '@/router/routes/signup';
import { routesUser } from '@/router/routes/userSettings';
import { RootState } from '@/store/model';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  ...routesLogin,
  ...routesSignUp,
  ...routesUser,
  ...getRoutesDev<RootState>(),
  ...getRoutesVisitsPage<RootState>(),
  ...getRoutesSchedulingFlow<RootState>(),
  ...routesHome,
  // TODO: Fix: 404 not working with routes with one segment (e.g., /invalid) due
  // to partnerValidationGuard (redirects to home)
  {
    name: routeNames.PAGE_NOT_FOUND,
    path: '*',
    component: () => import(
      /* webpackChunkName: 'chunk-main' */
      /* webpackMode: 'eager' */
      '@/views/PageNotFound.vue'
    ),
  },
];

/**
 * `initRouter` must not be called until feature flags are loaded.
 *
 * `augmentRouter` applies shared router logic that is not app-specific. It must
 * be called prior to using `getRouter`, `getRoute`, and `getPrevRoute`
 * functions.
 */
export const initRouter = () => {
  /**
   * Rather than exporting the router, use `getRouter()` to access the instance.
   */
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      return { x: 0, y: 0 };
    },
  });

  const { allowRoute, checkSignInToken } = authGuard(router);
  const { allowFeature } = featureFlagGuard(router);
  const { checkPartnerRoute } = partnerValidationGuard(router);
  const { setLoading } = loadingGuard(router);

  /**
   * When navigating back to the scheduling flow from the login page,
   * the redirect guard will refrain the scheduling flow from reinitializing.
   */
  const schedulingFlowRedirectGuard = getRedirectGuardSchedulingFlow(routeNames.LOGIN);

  // Previously we used beforeResolve for some guards to ensure vuex
  // would be initialized in time. Replacing router.$app.$store with getStore()
  // in the guards allows us to avoid this issue, but specific testing should be
  // completed when changing guards to beforeEach.
  router.beforeEach(setLoading(true));
  router.beforeEach(schedulingFlowRedirectGuard);
  router.beforeResolve(checkSignInToken);
  router.beforeResolve(allowRoute);
  router.beforeResolve(allowFeature);
  router.beforeResolve(checkPartnerRoute);
  router.afterEach(setLoading(false));
  router.afterEach(trackPageViewSegment);

  augmentRouter(router, {
    allowRoute,
  });
};
