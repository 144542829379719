import { getStore } from '@caresend/ui-components';
import { computed } from 'vue';
import { Route, RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesLogin: RouteConfig[] = [
  {
    path: '/forgotpassword',
    name: routeNames.FORGOT_PASSWORD,
    component: () => import(
      /* webpackChunkName: 'chunk-forgot-password' */
      '@/views/signUp/ForgotPassword.vue'
    ),
  },
  {
    path: '/:partnerName?/login',
    name: routeNames.LOGIN,
    component: () => import(
      /* webpackChunkName: 'chunk-main' */
      /* webpackMode: 'eager' */
      '@/views/login/LoginPage.vue'
    ),
    meta: {
      hideSignInButton: true,
      loadUntilTrue: () => computed<boolean>(() =>
        !getStore().state.app.loadingIDs[routeNames.LOGIN],
      ),
      loadingIdTrackingEnabled: true,
      // Display custom loading messages for the login page depending on where
      // the user came from.
      loadingMessage: (route: Route | undefined) => {
        const { query } = route ?? {};
        const { redirect } = query ?? {};
        if (redirect && typeof redirect === 'string') {
          return redirect.includes('/schedule/')
            ? 'Retrieving order…'
            : undefined;
        }
        return undefined;
      },
    },
  },
];

export { routesLogin };
