import { getStore } from '@caresend/ui-components';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';

export default (_router: VueRouter) => ({
  setLoading: (loading: boolean) => (
    to: Route,
    from: Route,
    next?: NavigationGuardNext<Vue>,
  ) => {
    const store = getStore();
    store.dispatch('app/updateRouteLoading', loading);
    // Only display loading screen on route change if it's not instant. Typically
    // delays would be caused if js is being downloaded for a code split route.
    if (loading) {
      setTimeout(() => {
        store.dispatch('app/showRouteLoading');
      }, 500);

      if (
        to.meta?.loadingIdTrackingEnabled
        && to.name
        && from.name !== to.name
      ) {
        store.commit('app/SET_LOADING_IDS', { [to.name]: loading });
      }
    }
    next?.();
  },
});
