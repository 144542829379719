import {
  augmentStore,
  initAppWatches,
  initAuthWatches,
  initFirebaseAuthWatch,
  isProd,
  waypointModule,
} from '@caresend/ui-components';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';

import { handleAuthInitializedWithoutUser } from '@/database/firebase/auth';
import { CustomModules, CustomStore, RootState } from '@/store/model';
import { appModule } from '@/store/modules/app';
import { authModule, handleUserBound, handleUserObjectUpdated, handleUserUnbound } from '@/store/modules/auth';
import { bookingsModule } from '@/store/modules/bookings';
import { officesModule } from '@/store/modules/offices';
import { orderFlowModule } from '@/store/modules/orderFlow';
import { ordersModule } from '@/store/modules/orders';
import { placesModule } from '@/store/modules/places';
import { proceduresModule } from '@/store/modules/procedures';
import { schedulingFlowModule } from '@/store/modules/schedulingFlow';
import { transactionsModule } from '@/store/modules/transactions';
import { usersModule } from '@/store/modules/users';
import { variablesModule } from '@/store/modules/variables';

Vue.use(Vuex);

const strict = !isProd;

const vuexPersistence = new VuexPersistence<RootState>({
  reducer: (state) => ({
    auth: {
      authStatus: state.auth.authStatus,
      user: state.auth.user,
    },
    variables: {
      featureFlagOverrides: state.variables.featureFlagOverrides,
    },
    schedulingFlow: state.schedulingFlow,
    orderFlow: state.orderFlow,
  }),
  strictMode: strict,
});

const modules: CustomModules = {
  app: appModule,
  auth: authModule,
  bookings: bookingsModule,
  offices: officesModule,
  orderFlow: orderFlowModule,
  orders: ordersModule,
  places: placesModule,
  procedures: proceduresModule,
  schedulingFlow: schedulingFlowModule,
  transactions: transactionsModule,
  users: usersModule,
  variables: variablesModule,
  waypoint: waypointModule,
};

const storeOptions: StoreOptions<RootState> = {
  modules,
  mutations: {
    RESTORE_MUTATION: vuexPersistence.RESTORE_MUTATION,
  },
  plugins: [vuexPersistence.plugin],
  strict,
};

/**
 * Rather than exporting the store, use `getStore()` to access the instance.
 */
const store: CustomStore = new Vuex.Store(storeOptions);

/**
 * `augmentStore` applies shared store logic that is not app-specific. It must
 * be called prior to using `getStore`.
 */
export const initStore = () => {
  augmentStore(store);

  initAppWatches();
  initAuthWatches({
    handleUserBound,
    handleUserObjectUpdated,
    handleUserUnbound,
  });
  initFirebaseAuthWatch({ handleAuthInitializedWithoutUser });
};
