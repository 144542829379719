import { Role, UserStatus } from '@caresend/types';
import { clearEncryptionCache, firebaseAuth, isProdDeployment } from '@caresend/ui-components';
import { hasStatus } from '@caresend/utils';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';

import { signInWithToken } from '@/database/firebase/token';
import { getMatchedMeta, routeNames } from '@/router/model';

export default (router: VueRouter) => ({
  allowRoute: (
    to: Route,
    from: Route,
    next: NavigationGuardNext<Vue>,
  ) => {
    // Using router.app.$store avoids circular dependencies if using the router
    // within store modules.
    const {
      authRequired,
      allowIncomplete,
      devOnly,
      authSubMeta,
    } = getMatchedMeta(to);
    const { bookingID, draftWaypointID, partnerName } = to.params;

    const user = router.app.$store.getters['auth/getUser'];

    if (authRequired) {
      const rejectAppAccess = !user || user.role !== Role.PATIENT;
      const rejectStatusAccess = !allowIncomplete && hasStatus(user, UserStatus.INCOMPLETE);

      if (rejectAppAccess || rejectStatusAccess) {
        const redirect = to.fullPath;

        clearEncryptionCache();
        firebaseAuth.signOut();

        const query: Dictionary<string> = { redirect };
        if (allowIncomplete) {
          query.allowIncomplete = String(allowIncomplete);
          // For access to booking page
          if (bookingID) query.bookingID = bookingID;
          // For access to scheduling flow
          if (draftWaypointID) query.draftWaypointID = draftWaypointID;
        }

        const deferSignup = authSubMeta?.deferSignup;
        const continueFlow = authSubMeta?.continueFlow;
        if (deferSignup) query.deferSignup = String(deferSignup);
        if (continueFlow) query.continueFlow = String(continueFlow);

        return next({
          name: routeNames.LOGIN,
          params: { partnerName: partnerName ?? null },
          query,
        });
      }
    }

    if (devOnly && isProdDeployment) {
      return next({
        name: routeNames.PAGE_NOT_FOUND,
        params: { partnerName: partnerName ?? null },
      });
    }

    return next();
  },
  checkSignInToken: async (to: Route,
    from: Route,
    next: NavigationGuardNext<Vue>) => {
    if (to.query.redirect?.includes('token=')) {
      const token = (to.query.redirect as string).split('token=')[1];
      if (!token) throw new Error('Token not found');
      const authUser = await signInWithToken({ token, to });
      if (authUser) {
        await router.app.$store.dispatch('auth/bindUser', authUser);
      }
    }
    return next();
  },
}
);
